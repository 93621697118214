<template>
<div class="app-records-page">
  <div class="page__wrapper">
    <div class="page__header-actions">
      <a-button type="text" @click="toIndexPage">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        back to index page
      </a-button>
    </div>

    <a-spin tip="Loading..." :spinning="spinning">
      <div class="page__body">
        <a-row>
          <!-- Stat -->
          <a-col :span="6">
            <a-statistic title="Transfer Out Amount" :value="totalTransferOutAmount" />
          </a-col>
          <!-- Stat -->

          <!-- Records -->
          <a-col :span="18">
            <a-table :dataSource="recordsData" :columns="columns">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'to_user_name'">
                  {{ record.to_user_name }}兌換所
                </template>

                <template v-if="column.key === 'amount'">
                  <span :style="{ fontWeight: 'bold' }">{{ record.amount }}</span>
                </template>

                <template v-if="column.key === 'paid'">
                  <template v-if="record.paid">
                    <a-tag color="#108ee9">Paid</a-tag>
                  </template>
                  <template v-else>
                   <a-tag color="#f50">Not Paid</a-tag>
                  </template>
                </template>

                <template v-if="column.key === 'created_at'">
                  {{ DateUtil.formatDatetime(record.created_at) }}
                </template>
              </template>
            </a-table>
          </a-col>
          <!-- Records -->
        </a-row>
      </div>
    </a-spin>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {
    ArrowLeftOutlined
  },
  setup() {
    return {
      DateUtil
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.init();
  },
  data() {
    return {
      spinning: false,

      exchangesData: [],
      recordsData: [],

      // table columns
      columns: [{
        title: 'From',
        dataIndex: 'from_user_name',
        key: 'from_user_name'
      }, {
        title: 'To',
        dataIndex: 'to_user_name',
        key: 'to_user_name'
      }, {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount'
      }, {
        title: 'Paid',
        dataIndex: 'paid',
        key: 'paid'
      }, {
        title: 'Note',
        dataIndex: 'note',
        key: 'note'
      }, {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at'
      }],

      // stat
      totalTransferOutAmount: 0
    }
  },
  methods: {
    /* router */
    toIndexPage() {
      this.$router.push({ name: 'AppIndex' });
    },

    /* init functions */
    async init() {
      this.spinning = true;
      await this.getExchangeAccounts();
      await this.getTransferRecords();
      this.spinning = false;
    },

    /* api */
    async getExchangeAccounts() {
      const url = process.env.VUE_APP_API + `/users?role=3&coop_info.id=${this.$store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      console.log(response.data);
      this.exchangesData = response.data;
    },
    async getTransferRecords() {
      // init
      this.recordsData = [];
      this.totalTransferOutAmount = 0;

      // find transactions
      const url = process.env.VUE_APP_API + `/admin-transactions?coop_info=${this.$store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      const resData = response.data;

      // combine exchage_info
      for (let i=0; i<resData.length; i++) {
        const item = resData[i];

        let record = {
          from_user_name: 'Me',
          from_user_address: this.$store.state.wallet.address,
          to_user_name: '',
          to_user_address: '',
          amount: item.amount,
          paid: item.paid,
          note: item.note,
          tx_hash: item.tx_hash,
          tx_id: item.tx_id,
          created_at: item.created_at
        }

        for (let j=0; j<this.exchangesData.length; j++) {
          const exchange = this.exchangesData[j];

          if (item.to_user.id == exchange.id) {
            record.to_user_name = exchange.ex_user_info.exchange_name;
            record.to_user_address = exchange.wallet.wallet_Address;
            break
          }
        }

        this.recordsData.push(record);
        this.totalTransferOutAmount += record.amount;
      }
    }
  }
})
</script>

<style lang="scss">
.app-records-page {
  .page__wrapper {
    width: 950px;
    margin: 0 auto;

    .page__header-actions {
      margin-bottom: 36px;
    }
  }
}
</style>